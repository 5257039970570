$(document).ready(function () {
  let p = $("body").attr("p");
  let a = $("body").attr("a");
  let event_id = $("body").attr("event_id");
  if (p === "statistic_day_report") {
    $(document).on("click", "#download-pdf", function () {
      const selectedDate = $("#report-date").val();

      $.ajax({
        type: "GET",
        url: `/api/v1/private/day-report/download?location_id=${event_id}&type=pdf&selectedDate=${selectedDate}`,
      })
        .done(function (data) {
          if (data.success && data.success === true) {
            const url = `/location/${event_id}/statistic/day_report/download_pdf?selectedDate=${selectedDate}`;
            window.location.href = url;
          }
        })
        .fail(function (data) {
          if (
            data.responseJSON.error &&
            data.responseJSON.error.message !== ""
          ) {
            showToast({
              type: 'error',
              title: 'Error',
              message: data.responseJSON.error.message,
              position: 'top-right',
              delay: 4000
            });
          }
        });
    });

    $(document).on("click", "#download-excel", function () {
      const selectedDate = $("#report-date").val();

      $.ajax({
        type: "GET",
        url: `/api/v1/private/day-report/download?location_id=${event_id}&type=excel&selectedDate=${selectedDate}`,
      })
        .done(function (data) {
          if (data.success && data.success === true) {
            const url = `/location/${event_id}/statistic/day_report/download_excel?selectedDate=${selectedDate}`;
            window.location.href = url;
          }
        })
        .fail(function (data) {
          if (
            data.responseJSON.error &&
            data.responseJSON.error.message !== ""
          ) {
            showToast({
              type: 'error',
              title: 'Error',
              message: data.responseJSON.error.message,
              position: 'top-right',
              delay: 4000
            });
          }
        });
    });
  }
});

$(document).ready(function () {
  let p = $("body").attr("p");

  if (p === "gift_card") {
    let paymentMethod = "";

    $(document).on("blur", "#recipient-email", function (e) {
      const email = $(this).val();
      if (!isValidEmail(email)) {
        $("#email-error").removeClass("d-none");
      } else {
        $("#email-error").addClass("d-none");
      }
      return;
    });

    $(document).on("click", ".order-button", function (e) {
      e.preventDefault();
      paymentMethod = $(this).attr("data-payment-method");

      $("#gift-card-form").submit();
    });

    $(document).on("click", ".gift-card-amount", function (e) {
      e.preventDefault();
      const giftCardAmount = $(this).attr("data-gift-card-amount");
      $("#gift-card-amount-input")
        .fadeOut(200)
        .queue(function () {
          $(this).val(giftCardAmount).fadeIn(200).dequeue();
        });

      if (!$("#gift-card-amount-alert").hasClass("d-none")) {
        $("#gift-card-amount-alert").addClass("d-none");
      }
    });

    $(document).on("change", "#gift-card-amount-input", function (e) {
      e.preventDefault();
      const giftCardAmount = parseFloat($(this).val());
      const maxAmount = parseFloat($(this).attr("data-max-amount"));
      const minAmount = parseFloat($(this).attr("data-min-amount"));

      if (giftCardAmount > maxAmount) {
        $("#gift-card-amount-alert").text(
          `Beløpet kan ikke være større enn ${maxAmount.toLocaleString(
            "nb-NO",
            {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }
          )}.`
        );
        $("#gift-card-amount-alert").removeClass("d-none");
        $(this).focus();
        return;
      } else if (giftCardAmount < minAmount) {
        $("#gift-card-amount-alert").text(
          `Beløpet kan ikke være mindre enn ${minAmount.toLocaleString(
            "nb-NO",
            {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }
          )}.`
        );
        $("#gift-card-amount-alert").removeClass("d-none");
        $(this).focus();
        return;
      } else {
        if (!$("#gift-card-amount-alert").hasClass("d-none")) {
          $("#gift-card-amount-alert").addClass("d-none");
        }
        return;
      }
    });

    $(document).on("submit", "#gift-card-form", function (e) {
      e.preventDefault();

      const formData = new FormData(e.target);

      if (!isValidEmail(formData.get("recipient_email"))) {
        $("#email-error").removeClass("d-none");
        $("#recipient-email").focus();
        return;
      }

      if (!checkRequiredFields(formData)) {
        return;
      }

      const giftCardAmountInput = $("#gift-card-amount-input");
      const giftCardAmount = parseFloat($(giftCardAmountInput).val());
      const maxAmount = parseFloat(
        $(giftCardAmountInput).attr("data-max-amount")
      );
      const minAmount = parseFloat(
        $(giftCardAmountInput).attr("data-min-amount")
      );

      if (giftCardAmount > maxAmount) {
        $("#gift-card-amount-alert").text(
          `Beløpet kan ikke være større enn ${maxAmount.toLocaleString(
            "nb-NO",
            {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }
          )}.`
        );
        $("#gift-card-amount-alert").removeClass("d-none");
        $(giftCardAmountInput).focus();
        return;
      } else if (giftCardAmount < minAmount) {
        $("#gift-card-amount-alert").text(
          `Beløpet kan ikke være mindre enn ${minAmount.toLocaleString(
            "nb-NO",
            {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }
          )}.`
        );
        $("#gift-card-amount-alert").removeClass("d-none");
        $(giftCardAmountInput).focus();
        return;
      } else {
        if (!$("#gift-card-amount-alert").hasClass("d-none")) {
          $("#gift-card-amount-alert").addClass("d-none");
        }
      }

      let api = "/api/v1/gift-card/create";
      if(paymentMethod === "payment_cash" || paymentMethod === "payment_preprint") {
        api = "/api/v1/private/gift-card/create"
      }
      
      formData.append("payment_method", paymentMethod);
      $.ajax({
        type: "POST",
        url: api,
        data: formData,
        enctype: "multipart/form-data",
        async: true,
        cache: false,
        processData: false,
        contentType: false,
      })
        .done(function (data) {
          if (
            data.payment_redirect_url !== undefined &&
            data.payment_redirect_url !== null
          ) {
            document.location.href = data.payment_redirect_url;
          }
        })
        .fail(function (data) {
          if (data.responseJSON.redirect_url) {
            document.location.href = data.responseJSON.redirect_url;
          }

          if (data.error) {
            return alert(data.error);
          }
        });
    });

    function checkRequiredFields(formData) {
      const requiredFields = ["amount", "recipient_email", "phone_number"];
      const missingFields = requiredFields.some(
        (field) => !formData.get(field)
      );

      return true;
    }
  }
});

$(document).ready(function () {
  let p = $("body").attr("p");
  let a = $("body").attr("a");
  const locationId = $("body").attr("event_id");

  if (p === "z_report") {
    let page = 1;
    $(document).on(
      "change",
      "#bankakspet, #visa, #mastercard, #othercard, #cash",
      function (e) {
        e.preventDefault();
        let totalAmount = 0;
        let posAmount = floor($("#total-pos-amount").attr("data-amount"));

        const ids = [
          "#bankakspet",
          "#visa",
          "#mastercard",
          "#othercard",
          "#cash",
        ];
        ids.forEach(function (id) {
          let value = $(id).val();
          if (value === "") {
            totalAmount += 0;
          } else if (typeof value === "string" && value.includes(",")) {
            totalAmount += floor(value.replace(",", "."));
          } else {
            totalAmount += floor(value);
          }
        });

        let diffAmount = floor(totalAmount - posAmount);

        $("#total-amount").text(formattedPriceToShow(totalAmount));
        $("#diff-amount").text(formattedPriceToShow(diffAmount));
      }
    );

    $(document).on("click", "#register-confirm", function (e) {
      $("#z-report-form").submit();
    });

    $(document).on("submit", "#z-report-form", function (e) {
      e.preventDefault();
      const confirmButton = $("#register-confirm");
      const buttonText = confirmButton.text();
      confirmButton.prop("disabled", true);
      $("#register-cancel").prop("disabled", true);
      const formData = new FormData($(this)[0]);
      const printZreport = $("#print-z-report").is(":checked");

      confirmButton.empty();
      confirmButton.append('<div class="loader"></div>');

      for (let [key, value] of formData.entries()) {
        // Check if the value is empty
        if (value === "") {
          formData.set(key, 0);
        } else if (typeof value === "string" && value.includes(",")) {
          formData.set(key, value.replace(",", "."));
        }
      }

      formData.append("location_id", locationId);
      formData.append("print_z_report", printZreport);

      $.ajax({
        type: "POST",
        url: "/api/v1/private/z-report/new",
        data: formData,
        enctype: "multipart/form-data",
        async: true,
        cache: false,
        processData: false,
        contentType: false,
      })
        .done(function (data) {
          if (printZreport) {
            passDataToWebView(
              e,
              "print_z_report",
              data.printData,
              data.ipAddress,
              data.type
            );
          }

          if (
            data.error?.message &&
            data.error?.message !== null &&
            data.error?.message !== ""
          ) {
            showToast({
              type: "error",
              title: "Error",
              message: data.error.message,
              position: "top-right",
              delay: 4000,
            });
          }

          $("div.loader").remove();
          confirmButton.text(buttonText);
          confirmButton.prop("disabled", false);

          if (
            data.downloadUrl !== "" &&
            data.downloadUrl !== null &&
            data.downloadUrl !== undefined
          ) {
            window.location.href = data.downloadUrl;
          }

          setTimeout(function () {
            location.href = `/location/${locationId}/z-report`;
          }, 1000);
        })
        .fail(function (data) {
          showToast({
            type: "error",
            title: "Error",
            message: data.responseJSON.error.message,
            position: "top-right",
            delay: 4000,
          });

          $("#register-confirm").prop("disabled", false);
          $("#register-cancel").prop("disabled", false);
        });
    });

    $(document).on("click", "#z-report-download-pdf", function () {
      const zReportId = $(this).attr("data-z-report-id");

      const url = `/api/v1/z-report-public/download_pdf?z_report_id=${zReportId}&location_id=${locationId}`;
      window.location.href = url;
    });

    $(document).on("click", "#print-zreport", function (e) {
      const zReportId = $(this).attr("data-z-report-id");
      const button = $(this);
      const buttonText = button.text();
      button.prop("disabled", true);

      button.empty();
      button.append('<div class="loader"></div>');

      $.ajax({
        type: "POST",
        url: "/api/v1/private/z-report/print",
        data: {
          z_report_id: zReportId,
          location_id: locationId,
        },
      })
        .done(function (data) {
          console.log(data);

          if (data && data.printData && data.ipAddress && data.type) {
            passDataToWebView(
              e,
              "print_z_report",
              data.printData,
              data.ipAddress,
              data.type
            );
          }

          $("div.loader").remove();
          button.prop("disabled", false);
          button.text(buttonText);
        })
        .fail(function (data) {
          if (
            data.responseJSON !== undefined &&
            data.responseJSON.error.message !== undefined &&
            data.responseJSON.error.message !== null
          ) {
            showToast({
              type: "error",
              title: "Error",
              message: data.responseJSON.error.message,
              position: "top-right",
              delay: 4000,
            });
          }
          $("div.loader").remove();
          button.prop("disabled", false);
          button.text(buttonText);
        });
    });

    //start-end date filter
    $(document).on("change", "#start-date", function (e) {
      const startDate = $(this).val();
      const endDate = $("#end-date").val();

      if (checkDateValidation(startDate, endDate)) {
        getZreport(locationId, startDate, endDate);
      } else {
        $(this).focus();
        return;
      }
    });

    $(document).on("click", ".pagination a", function (e) {
      e.preventDefault();
      const filterStartDate = $("#start-date").val();
      const filterEndDate = $("#end-date").val();
      page = parseInt($(this).attr("href").substring(6));
      getZreport(locationId, filterStartDate, filterEndDate, page);
    });

    $(document).on("change", "#end-date", function (e) {
      const endDate = $(this).val();
      const startDate = $("#start-date").val();

      if (checkDateValidation(startDate, endDate)) {
        getZreport(locationId, startDate, endDate);
      } else {
        $(this).focus();
        return;
      }
    });

    function checkDateValidation(filterStartDate, filterEndDate) {
      if (!filterStartDate || !filterEndDate) {
        alert("Både startdato og sluttdato er obligatorisk.");
        return false;
      }

      if (new Date(filterEndDate) < new Date(filterStartDate)) {
        alert("Sluttdato kan ikke være før startdato.");
        return false;
      }

      return true;
    }

    function getZreport(locationId, startDate, endDate, page) {
      $.ajax({
        type: "POST",
        url: "/api/v1/private/z-report/search",
        data: {
          location_id: locationId,
          start_date: startDate,
          end_date: endDate,
          page,
        },
      })
        .done(function (data) {
          if (data.html && data.html !== null && data.html !== "") {
            $("#z-report-list-view").empty();
            $("#z-report-list-view").html(data.html);
          }
        })
        .fail(function (data) {
          if (
            data.responseJSON.redirect_url !== undefined &&
            data.responseJSON.redirect_url !== null
          ) {
            document.location.href = data.responseJSON.redirect_url;
          }

          if (
            data.responseJSON.message !== undefined &&
            data.responseJSON.message !== null
          ) {
            $("#inventory-stock-history-response").empty();
            $("#inventory-stock-history-response").append(
              data.responseJSON.message
            );
          }
        });
    }

    $(document).on("click", "#download-all", function () {
      const start_date = $("#start-date").val();
      const end_date = $("#end-date").val();

      const url = `/api/v1/z-report-public/download-all?start_date=${start_date}&end_date=${end_date}&location_id=${locationId}`;
      window.location.href = url;
    });
  }
});

$(document).ready(function () {
  let filterByFromQuery = $("body").attr("filter_by") || null;
  let startDateFromQuery = $("body").attr("start_date") || null;
  let endDateFromQuery = $("body").attr("end_date") || null;
  let customPeriodFromQuery = $("body").attr("custom_period") || null;
  let prevSelectedPeriodFromQuery =
    $("body").attr("prev_selected_period") || null;
  if (p === "statistic_payment") {
    setDateAndPeriod(
      startDateFromQuery,
      endDateFromQuery,
      filterByFromQuery,
      customPeriodFromQuery,
      prevSelectedPeriodFromQuery
    );
    handleCustomerIdChange(getPaymentMethod);
    handleCustomPeriodChange(getPaymentMethod);
    handleTopProductGroupChange(getPaymentMethod);
    handleSupplierIdChange(getPaymentMethod);
    handleProductGroupChange(getPaymentMethod);
    handleUserChange(getPaymentMethod);
    handlePlatformChange(getPaymentMethod);
    handlePeriodChange(getPaymentMethod);
    handleDateAndTimeChange(getPaymentMethod);
    handlePreviousButton(getPaymentMethod);
    handleNextButton(getPaymentMethod);
    handleCompareByChange(getPaymentMethod);
    handleProductChange(getPaymentMethod);
    handlePaymentChange(getPaymentMethod);
    handleVatCodeChange(getPaymentMethod);

    function eventLineChart(seriesData, interval = null) {
      let categories = seriesData.map((item) => item.name); // Extract dates for x-axis categories

      // Prepare the series array
      let series = [
        {
          name: "Vipps",
          data: seriesData.map((item) => floor(item.data[0][0])), // First line of Vipps
          stack: "Vipps",
        },
        {
          name: "Kort",
          data: seriesData.map((item) => floor(item.data[0][1])), // First line of Kort
          stack: "Kort",
        },
        {
          name: "Terminal",
          data: seriesData.map((item) => floor(item.data[0][2])), // First line of Terminal
          stack: "Terminal",
          color: "grey",
        },
        {
          name: "Kontanter",
          data: seriesData.map((item) => floor(item.data[0][3])), // First line of Kontanter
          stack: "Kontanter",
        },
      ];

      // Conditionally add last week and last year data if compareBy is not null or "0"
      if (compareBy && compareBy !== "0") {
        series = [
          {
            name: `Vipps (${formattedPriceToShow(
              seriesData.reduce((sum, item) => sum + floor(item.data[0][0]), 0)
            )})`,
            data: seriesData.map((item) => floor(item.data[0][0])),
            stack: "Vipps",
          },
          {
            name: `Vipps forrige ${getPeriodTranslation(
              compareBy ?? "",
              startDate
            )} (${formattedPriceToShow(
              seriesData.reduce((sum, item) => sum + floor(item.data[1][0]), 0)
            )})`,
            data: seriesData.map((item) => floor(item.data[1][0])),
            stack: "Vipps",
          },

          {
            name: `Kort (${formattedPriceToShow(
              seriesData.reduce((sum, item) => sum + floor(item.data[0][1]), 0)
            )})`,
            data: seriesData.map((item) => floor(item.data[0][1])),
            stack: "Kort",
          },
          {
            name: `Kort forrige ${getPeriodTranslation(
              compareBy ?? "",
              startDate
            )} (${formattedPriceToShow(
              seriesData.reduce((sum, item) => sum + floor(item.data[1][1]), 0)
            )})`,
            data: seriesData.map((item) => floor(item.data[1][1])),
            stack: "Kort",
          },

          {
            name: `Terminal (${formattedPriceToShow(
              seriesData.reduce((sum, item) => sum + floor(item.data[0][2]), 0)
            )})`,
            data: seriesData.map((item) => floor(item.data[0][2])),
            stack: "Terminal",
          },
          {
            name: `Terminal forrige ${getPeriodTranslation(
              compareBy ?? "",
              startDate
            )} (${formattedPriceToShow(
              seriesData.reduce((sum, item) => sum + floor(item.data[1][2]), 0)
            )})`,
            data: seriesData.map((item) => floor(item.data[1][2])),
            stack: "Terminal",
          },

          {
            name: `Kontanter (${formattedPriceToShow(
              seriesData.reduce((sum, item) => sum + floor(item.data[0][3]), 0)
            )})`,
            data: seriesData.map((item) => floor(item.data[0][3])),
            stack: "Kontanter",
          },
          {
            name: `Kontanter forrige ${getPeriodTranslation(
              compareBy ?? "",
              startDate
            )} (${formattedPriceToShow(
              seriesData.reduce((sum, item) => sum + floor(item.data[1][3]), 0)
            )})`,
            data: seriesData.map((item) => floor(item.data[1][3])),
            stack: "Kontanter",
          },
        ];
      }

      // Render the chart
      Highcharts.chart("event-line-graph", {
        chart: { type: "column" },
        title: {
          text: null,
        },
        exporting: { enabled: false },
        plotOptions: {
          series: {
            lineWidth: 2, // Adjust line width as needed
            stacking: "normal", // Enable stacking of columns in the same group
          },
        },
        xAxis: {
          categories: categories,
          labels: {
            formatter: function () {
              if (startDate.split(" ")[0] == endDate.split(" ")[0]) {
                return this.value;
              } else if (customPeriod === "week") {
                return "Uke" + " " + this.value;
              } else if (customPeriod === "month") {
                return Highcharts.dateFormat(
                  "%B %Y",
                  new Date(this.value).getTime()
                );
              } else {
                const timestamp = new Date(this.value).getTime();
                return Highcharts.dateFormat("%e / %b", timestamp);
              }
            },
          },
        },
        credits: { enabled: false },
        yAxis: {
          min: 0,
          title: {
            text: " ",
          },
        },
        series: series,
        tooltip: {
          formatter: function () {
            let tooltipText;
            let tooltipDate;
            let tooltipWeek;

            if (this.series.name.includes("uke")) {
              let date = moment(this.x);
              date.subtract(1, "week");
              tooltipDate = date.valueOf();
            } else if (this.series.name.includes("forrige")) {
              let date = moment(this.x);
              date.subtract(1, "year");
              tooltipDate = date.valueOf();
            } else {
              tooltipDate = this.x;
            }

            if (this.series.name.includes("uke")) {
              tooltipWeek = parseInt(this.x) - 1;
            } else {
              tooltipWeek = this.x;
            }

            if (startDate.split(" ")[0] == endDate.split(" ")[0]) {
              tooltipText =
                "<b>" +
                Highcharts.dateFormat("%H:%M, %b %e", tooltipDate) +
                "</b><br/>" +
                this.series.name +
                " : " +
                formattedPriceToShow(this.y);
            } else if (customPeriod === "week") {
              tooltipText =
                "<b>" +
                "Uke" +
                " " +
                tooltipWeek +
                "</b><br/>" +
                this.series.name +
                " : " +
                formattedPriceToShow(this.y);
            } else if (customPeriod === "month") {
              tooltipText =
                "<b>" +
                Highcharts.dateFormat("%B - %Y", tooltipDate) +
                "</b><br/>" +
                this.series.name +
                " : " +
                formattedPriceToShow(this.y);
            } else {
              tooltipText =
                "<b>" +
                Highcharts.dateFormat("%A, %b %e, %Y", tooltipDate) +
                "</b><br/>" +
                this.series.name +
                " : " +
                formattedPriceToShow(this.y);
            }
            return tooltipText;
          },
        },
      });
    }

    function dateAndPeriodValidation(startedDate, endedDate) {
      let start = moment(startedDate);
      let end = moment(endedDate);

      if (start.isAfter(end)) {
        $("#start-date").val(startDate);
        $("#end-date").val(endDate);
        return alert("end date cannot be later than start date");
      } else {
        return true;
      }
    }

    function toggleCustomDatePicker() {
      let customDatePicker = $(".custom-date-picker");

      if (period === "custom") {
        customDatePicker.show();
      } else {
        customDatePicker.hide();
      }
    }

    function getPaymentMethod(
      startDate,
      endDate,
      period = null,
      supplierId = null,
      productGroupUniqueId = null,
      locationId = null,
      userId = null,
      platform = "all",
      customPeriod,
      topProductGroupUniqueId = 0,
      customerId,
      compareBy = null,
      productId = 0,
      vatCode = 0,
      paymentMethod = 0
    ) {
      $.ajax({
        type: "POST",
        url: "/ajax/statistic_payment/search",
        data: {
          startDate,
          endDate,
          period,
          supplierId,
          productGroupUniqueId,
          locationId,
          userId,
          platform,
          customPeriod,
          topProductGroupUniqueId,
          customerId,
          compareBy,
          productId,
          vatCode,
          paymentMethod,
        },
      })
        .done(function (data) {
          if (data.paymentCounts) {
            $("#key-figure-vipps").text(
              formattedPriceToShow(data.paymentCounts.vippsCount)
            );
            $("#key-figure-column-1").attr(
              "data-content",
              floor(data.paymentCounts.vippsCount, 3)
                .toString()
                .replace(".", ",")
            );
            $("#key-figure-card").text(
              formattedPriceToShow(data.paymentCounts.cardCount)
            );
            $("#key-figure-column-2").attr(
              "data-content",
              floor(data.paymentCounts.cardCount, 3)
                .toString()
                .replace(".", ",")
            );
            $("#key-figure-terminal").text(
              formattedPriceToShow(data.paymentCounts.terminalCount)
            );
            $("#key-figure-column-3").attr(
              "data-content",
              floor(data.paymentCounts.terminalCount, 3)
                .toString()
                .replace(".", ",")
            );
            $("#key-figure-cash").text(
              formattedPriceToShow(data.paymentCounts.cashCount)
            );
            $("#key-figure-column-4").attr(
              "data-content",
              floor(data.paymentCounts.cashCount, 3)
                .toString()
                .replace(".", ",")
            );
          } else {
            $("#key-figure-vipps").text("0");
            $("#key-figure-card").text("0");
            $("#key-figure-terminal").text("0");
            $("#key-figure-cash").text("0");
          }
          if (data.data) {
            eventLineChart(data.data);
          }
          let aTags = $(".statistic-tab-group a");
          aTags.each(function () {
            let oldHref = $(this).attr("href").split("?")[0];
            let newHref =
              oldHref +
              `?filter_by=${period}&custom_period=${customPeriod}&prev_selected_period=${prevSelectedPeriod}&start_date=${startDate}&end_date=${endDate}`;
            $(this).prop("href", newHref);
          });
        })
        .fail(function (data) {
          if (
            data.responseJSON.redirect_url !== undefined &&
            data.responseJSON.redirect_url !== null
          ) {
            document.location.href = data.responseJSON.redirect_url;
          }
        });
    }

    function displayGraphDynamically(durationOfstartAndEndDate) {
      if (isMobile) {
        if (durationOfstartAndEndDate <= 7) {
          customPeriod = "day";
          $("#custom-period option[value='day']").prop("disabled", false);
          $("#custom-period option[value='day']").prop("selected", true);
          $("#custom-period option[value='week']").prop("disabled", false);
          $("#custom-period option[value='time']").prop("disabled", true);
          $("#custom-period option[value='time']").addClass("disabledOption");
          $("#custom-period option[value='day']").removeClass("disabledOption");
          $("#custom-period option[value='week']").removeClass(
            "disabledOption"
          );
        } else if (
          durationOfstartAndEndDate >= 8 &&
          durationOfstartAndEndDate < 49
        ) {
          //week
          customPeriod = "week";
          $(`#custom-period option[value="week"]`).prop("selected", true);
          $("#custom-period option[value='week']").prop("disabled", false);
          $("#custom-period option[value='time']").prop("disabled", true);
          $("#custom-period option[value='day']").prop("disabled", true);
          $("#custom-period option[value='time']").addClass("disabledOption");
          $("#custom-period option[value='day']").addClass("disabledOption");
          $("#custom-period option[value='week']").removeClass(
            "disabledOption"
          );
        } else {
          //month
          customPeriod = "month";
          $(`#custom-period option[value="week"]`).prop("selected", false);
          $(`#custom-period option[value="month"]`).prop("selected", true);
          $("#custom-period option[value='time']").prop("disabled", true);
          $("#custom-period option[value='day']").prop("disabled", true);
          $("#custom-period option[value='week']").prop("disabled", true);
          $("#custom-period option[value='time']").addClass("disabledOption");
          $("#custom-period option[value='day']").addClass("disabledOption");
          $("#custom-period option[value='week']").addClass("disabledOption");
        }
      } else if (isTablet) {
        if (durationOfstartAndEndDate <= 14) {
          //day
          customPeriod = "day";
          $("#custom-period option[value='day']").prop("disabled", false);
          $("#custom-period option[value='day']").prop("selected", true);
          $("#custom-period option[value='week']").prop("disabled", false);
          $("#custom-period option[value='time']").prop("disabled", true);
          $("#custom-period option[value='time']").addClass("disabledOption");
          $("#custom-period option[value='day']").removeClass("disabledOption");
          $("#custom-period option[value='week']").removeClass(
            "disabledOption"
          );
        } else if (
          durationOfstartAndEndDate >= 15 &&
          durationOfstartAndEndDate < 98
        ) {
          //week
          customPeriod = "week";
          $(`#custom-period option[value="week"]`).prop("selected", true);
          $("#custom-period option[value='week']").prop("disabled", false);
          $("#custom-period option[value='time']").prop("disabled", true);
          $("#custom-period option[value='day']").prop("disabled", true);
          $("#custom-period option[value='time']").addClass("disabledOption");
          $("#custom-period option[value='day']").addClass("disabledOption");
          $("#custom-period option[value='week']").removeClass(
            "disabledOption"
          );
        } else {
          //month
          customPeriod = "month";
          $(`#custom-period option[value="week"]`).prop("selected", false);
          $(`#custom-period option[value="month"]`).prop("selected", true);
          $("#custom-period option[value='time']").prop("disabled", true);
          $("#custom-period option[value='day']").prop("disabled", true);
          $("#custom-period option[value='week']").prop("disabled", true);
          $("#custom-period option[value='time']").addClass("disabledOption");
          $("#custom-period option[value='day']").addClass("disabledOption");
          $("#custom-period option[value='week']").addClass("disabledOption");
        }
      } else {
        if (durationOfstartAndEndDate <= 31) {
          //day
          customPeriod = "day";
          $("#custom-period option[value='day']").prop("disabled", false);
          $("#custom-period option[value='day']").prop("selected", true);
          $("#custom-period option[value='week']").prop("disabled", false);
          $("#custom-period option[value='time']").prop("disabled", true);
          $("#custom-period option[value='time']").addClass("disabledOption");
          $("#custom-period option[value='day']").removeClass("disabledOption");
          $("#custom-period option[value='week']").removeClass(
            "disabledOption"
          );
        } else if (
          durationOfstartAndEndDate >= 32 &&
          durationOfstartAndEndDate < 217
        ) {
          //week
          customPeriod = "week";
          $(`#custom-period option[value="week"]`).prop("selected", true);
          $("#custom-period option[value='week']").prop("disabled", false);
          $("#custom-period option[value='time']").prop("disabled", true);
          $("#custom-period option[value='day']").prop("disabled", true);
          $("#custom-period option[value='time']").addClass("disabledOption");
          $("#custom-period option[value='day']").addClass("disabledOption");
          $("#custom-period option[value='week']").removeClass(
            "disabledOption"
          );
        } else {
          //month
          customPeriod = "month";
          $(`#custom-period option[value="week"]`).prop("selected", false);
          $(`#custom-period option[value="month"]`).prop("selected", true);
          $("#custom-period option[value='time']").prop("disabled", true);
          $("#custom-period option[value='day']").prop("disabled", true);
          $("#custom-period option[value='week']").prop("disabled", true);
          $("#custom-period option[value='time']").addClass("disabledOption");
          $("#custom-period option[value='day']").addClass("disabledOption");
          $("#custom-period option[value='week']").addClass("disabledOption");
        }
      }
    }

    getPaymentMethod(
      startDate,
      endDate,
      period,
      supplierId,
      productGroupUniqueId,
      locationId,
      userId,
      platform,
      customPeriod,
      topProductGroupUniqueId,
      customerId,
      productId,
      vatCode,
      paymentMethod
    );
    toggleCustomDatePicker();
  }
});

$(document).ready(function () {
  let p = $("body").attr("p");
  let organizerId = $("body").attr("organizer_id");

  if (p === "organizer_gift_cards") {
    let filterBy = $("#filter-by").val();
    let order_by = "first_name";
    let order_by_direction = "desc";

    $(document).on("change", "#filter-by", function (e) {
      e.preventDefault();
      filterBy = $(this).val();

      if (!$(".empty-button").hasClass("d-none")) {
        $(".empty-button").addClass("d-none");
      }

      getGiftCardCustomerList(
        organizerId,
        order_by,
        order_by_direction,
        filterBy
      );
    });

    $(document).on("click", "#download-giftcard", function (e) {
      e.preventDefault();

      const url = `/api/v1/private/gift-card/download-gift-card?organizer_id=${organizerId}&filter_by=${filterBy}`;
      window.location.href = url;
    });

    $(document).on("click", ".empty-button", function (e) {
      e.preventDefault();
      $(this).prop("disabled", true);

      const url = `/api/v1/private/gift-card/download-and-empty?organizer_id=${organizerId}&filter_by=${filterBy}`;
      window.location.href = url;

      setTimeout(() => {
        $("#filter-by").val("all").trigger("change");
      }, 1000);
    });

    $(document).on("click", ".sort", function (e) {
      e.preventDefault();

      order_by = $(this).attr("id");
      order_by_direction = $(this).attr("data-order-direction");
      organizerId = $(this).closest("tr").attr("data-organizer-id");

      getGiftCardCustomerList(
        organizerId,
        order_by,
        order_by_direction,
        filterBy
      );
    });

    function getGiftCardCustomerList(
      organizerId,
      orderBy,
      orderByDirection,
      filterBy
    ) {
      $.ajax({
        type: "POST",
        url: "/api/v1/private/gift-card/search",
        data: {
          organizer_id: organizerId,
          order_by: orderBy,
          order_by_direction: orderByDirection,
          filter_by: filterBy,
        },
      })
        .done(function (data) {
          if (data.html) {
            $("#gift-card-list").empty().append(data.html);

            setTimeout(() => {
              if (filterBy == "deactive") {
                if ($("#gift-card-list-table tbody tr").length > 0) {
                  $(".empty-button").removeClass("d-none");
                  $(".empty-button").prop("disabled", false);
                }
              }
            }, 200);
          }
        })
        .fail(function (data) {
          if (data.responseJSON.redirect_url) {
            document.location.href = data.responseJSON.redirect_url;
          }

          if (data.error) {
            return alert(data.error);
          }
        });
    }

    function emptyExpiredGiftcard() {
      $.ajax({
        type: "POST",
        url: "/api/v1/private/gift-card/empty",
        data: {
          organizer_id: organizerId,
          order_by,
          order_by_direction,
          filter_by: filterBy,
        },
      })
        .done(function (data) {
          if (data.message) {
            popupAlert();

            setTimeout(() => {
              location.reload();
            }, 300);
          }
        })
        .fail(function (data) {
          if (data.responseJSON.redirect_url) {
            document.location.href = data.responseJSON.redirect_url;
          }

          if (data.error) {
            return alert(data.error);
          }
        });
    }
  }
});

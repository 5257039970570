$(document).ready(function () {
  let p = $("body").attr("p");
  let a = $("body").attr("a");
  let locationId = $("body").attr("event_id");
  let status = $("body").attr("status");
  let url = "";
  let printerId = null;

  $("select[name=type]").select2({
    theme: "bootstrap4",
  });

  //new
  if (p === "printer" || p === "organizer_settings") {
    $(document).on("change", "#main_printer", function () {
      if ($(this).is(":checked")) {
        $("#main-printer-confirm-model").modal("show");
      } else {
        $("#is_primary").val("0");
        return;
      }
    });

    $(document).on("click", "#main-printer-confirm", function () {
      $("#main_pos").prop("checked", true);
      $("#is_primary").val("1");
      $("#main-printer-confirm-model").modal("hide");
    });

    $(document).on("click", "#main-printer-cancel", function () {
      $("#main_printer").prop("checked", false);
      $("#is_primary").val("0");
    });

    //new
    $(document).on("submit", "#printer-registration-form", function (e) {
      e.preventDefault();

      const formData = new FormData($(this)[0]);
      formData.append("location_id", locationId);
      formData.append("status", 1);
      url = "/ajax/printer/new";
      savePrinter(formData, url);
    });

    //update
    $(document).on("submit", "#printer-update-form", function (e) {
      e.preventDefault();
      const formData = new FormData(this);
      formData.append("location_id", locationId);
      formData.append("status", status);

      if($(this).attr("data-printer-type") === 'TERMINAL') {
        formData.append("type", 'TERMINAL');
      }
      url = "/ajax/printer/update";
      savePrinter(formData, url);
    });

    //delete
    $(document).on("click", ".printer-delete-btn", function (e) {
      e.preventDefault();
      let idToDelete = $(this).attr("data-delete-id");
      $("#delete-printer-confirm").attr("data-delete-id", idToDelete);
    });

    $(document).on("click", "#delete-printer-confirm", function (e) {
      e.preventDefault();
      locationId = $(this).data("location-id");
      printerId = $(this).attr("data-delete-id");
      url = "/ajax/printer/delete";
      const formData = new FormData();
      formData.append("status", 0);
      formData.append("printer_id", printerId);
      formData.append("location_id", locationId);
      updateStatus(formData, url);
      $(".modal").modal("hide");
    });

    function savePrinter(formData, url) {
      $.ajax({
        type: "POST",
        url,
        data: formData,
        enctype: "multipart/form-data",
        async: true,
        cache: false,
        processData: false,
        contentType: false,
      })
        .done(function (data) {
          if (data.message !== undefined && data.message !== null) {
            prependAlert("#message", data.message, "success", null);
            $("#printer-registration-form").hide();
            $("#printer-update-form").hide();
            $("#message").show();

            setTimeout(function () {
              document.location.href = "/settings#printer-setting";
            }, 2000);
          }
        })
        .fail(function (data) {
          if (
            data.responseJSON.redirect_url !== undefined &&
            data.responseJSON.redirect_url !== null
          ) {
            document.location.href = data.responseJSON.redirect_url;
          }

          if (
            data.responseJSON.error_message !== undefined &&
            data.responseJSON.error_message !== null
          ) {
            prependAlert(
              "#message-box",
              data.responseJSON.error_message,
              "danger",
              null,
              "#printer-registration-form"
            );
          }
        });
    }

    function updateStatus(formData, url) {
      $.ajax({
        type: "POST",
        url,
        data: formData,
        enctype: "multipart/form-data",
        async: true,
        cache: false,
        processData: false,
        contentType: false,
      })
        .done(function (data) {
          if (data.alert) {
            alert(data.alert);

            return;
          }
          if (
            data.message !== undefined &&
            data.message !== null &&
            !data.alert
          ) {
            $("tr#printer-" + printerId).fadeOut(1000);
            setTimeout(function () {
              $("tr#printer-" + printerId).remove();
            }, 1000);
            $("#printer-mobile-list-" + printerId).fadeOut(1000);
            setTimeout(function () {
              $("#printer-mobile-list-" + printerId).remove();
            }, 1000);
            popupAlert();
          }
        })
        .fail(function (data) {
          if (
            data.responseJSON.redirect_url !== undefined &&
            data.responseJSON.redirect_url !== null
          ) {
            document.location.href = data.responseJSON.redirect_url;
          }

          if (
            data.responseJSON.error_message !== undefined &&
            data.responseJSON.error_message !== null
          ) {
            prependAlert(
              "#message-box",
              data.responseJSON.error_message,
              "danger",
              null,
              "#printer-update-form"
            );
          }
        });
    }
  }
});
